import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'
import useWidth from 'src/hooks/window/useWidth'

import { WIDTH_MD, WIDTH_XL, WIDTH_XXL } from 'src/styles/breakpoints'
import { Button, MasterCardWithCashback } from './style'

type InterLoopProps = {
  setIsModalOpenAccount: Function;
  setDataLayer: Function;
}

const InterLoop = ({ setDataLayer, setIsModalOpenAccount }: InterLoopProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const imageWidths: number[] = [
    312, 696, 936, 1128, 1128,
  ]
  const imageHeight: number[] = [
    196, 196, 234, 291, 291,
  ]

  const dataLayer: IDataLayerParams = {
    section: 'dobra_05',
    section_name: 'Conforto e exclusividade pra você.',
    element_action: 'click button',
    element_name: 'Abra a sua conta',
  }

  return (
    <MasterCardWithCashback
      id='inter-loop'
      className='py-5 pt-md-4'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='text-md-center'>
              {
                width < WIDTH_MD && (
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/salavip-loop-360/image.webp'
                    altDescription='Área de acesso ao Vip Lounge Inter'
                    arrayNumbers={imageWidths}
                    arrayNumbersHeight={imageHeight}
                  />
                )
              }
              {
                width >= WIDTH_MD && width <= WIDTH_XL && (
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/salavip-loop-1024-768/image.webp'
                    altDescription='Área de acesso ao Vip Lounge Inter'
                    arrayNumbers={imageWidths}
                    arrayNumbersHeight={imageHeight}
                  />
                )
              }
              {
                width >= WIDTH_XXL && (
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/salavip-loop-1440/image.webp'
                    altDescription='Área de acesso ao Vip Lounge Inter'
                    arrayNumbers={imageWidths}
                    arrayNumbersHeight={imageHeight}
                  />
                )
              }
            </div>
            <h2 className='fs-24 lh-28 fs-lg-28 lh-lg-34 fw-700 text-white font-citrina mt-4'>
              Pontos Loop
            </h2>
            <p className='fs-14 lh-17 fs-md-16 lh-md-20 fs-lg-18 lh-lg-22 text-white'>
              Todo cliente Inter pode ter acesso as nossas Salas Vip. Para isso, é só trocar seus pontos Loop
              por acesso ao Vip Lounge Inter.
            </p>
            <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 text-white mb-0'>
              Atenção: somente para as salas exclusivas do Inter nos seguintes aeroportos:
            </p>
            <ul className='fs-14 lh-17 text-white ml-3 mb-0'>
              <li>Aeroporto Internacional de São Paulo – Guarulhos – Terminal 2</li>
              <li>Aeroporto Internacional Afonso Pena – Curitiba – Dentro do Terminal de Embarque, em frente ao Portão 13.</li>
            </ul>
          </div>

          <div className='col-12'>
            <div className='d-flex justify-content-md-center my-4 py-3 my-md-5 py-md-0'>
              <Button
                aria-label='Abra a sua conta'
                className='btn btn-orange--extra rounded-2 text-none'
                onClick={() => {
                  setIsModalOpenAccount(true)
                  setDataLayer(dataLayer)
                  sendDatalayerEvent(dataLayer)
                }}
              >
                Abra a sua conta
              </Button>
            </div>

            <h3 className='fs-20 lh-24 text-white font-citrina fw-600'>Acompanhantes</h3>

            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white'>
              Para clientes Black ou Win, os acompanhantes com um cartão ligado à conta também têm acesso gratuito, mas é necessário
              emitir QR Code em nome próprio do acompanhante. Acompanhantes de cliente com cartão Black Promocional não tem
              acesso gratuito à sala VIP.
            </p>

            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white mb-md-4 mb-xl-5'>
              Caso o acompanhante não seja portador de um cartão Inter adicional, Black ou Win, será cobrado o valor de US$ 35
              por acesso para cada pessoa.
            </p>

            <h3 className='fs-20 lh-24 text-white font-citrina fw-600'>Acesso pelo desembarque</h3>

            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white mb-md-4 mb-xl-5'>
              As salas VIP Lounge Inter NÃO aceitam acesso no desembarque, apenas no embarque. As salas do Priority Pass
              podem ter acesso no desembarque, sendo necessário verificar disponibilidade junto a sala desejada.
            </p>

            <h3 className='fs-20 lh-24 text-white font-citrina fw-600'>Crianças</h3>

            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white mb-0'>
              Crianças até 6 anos: entrada gratuita com apresentação de documento
            </p>
            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white mb-0'>
              Crianças de 7 a 10 anos: entrada gratuita com apresentação de documento e Conta Kids ativa
            </p>
            <p className='fs-12 lh-15 fs-md-14 lh-md-17 text-white mb-0'>
              Crianças a partir de 11 anos: entrada com apresentação de documento e pontos Inter Loop
            </p>
          </div>
        </div>
      </div>
    </MasterCardWithCashback>
  )
}

export default InterLoop
